@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: gilroy-regular;
    src: url(./fonts/Gilroy-Regular.ttf);
}
@font-face {
    font-family: gilroy-medium;
    src: url(./fonts/Gilroy-Medium.ttf);
}
@font-face {
    font-family: gilroy-semibold;
    src: url(./fonts/Gilroy-Semibold.ttf);
}
@font-face {
    font-family: gilroy-bold;
    src: url(./fonts/Gilroy-Bold.ttf);
}

body {
    margin: 0;
    font-family: gilroy-regular;
    -webkit-font-smoothing: gilroy-regular;
    -moz-osx-font-smoothing: gilroy-regular;

    /* padding: 24px 80px; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.custom-border {
    border: 1px solid #e9eaee;
    border-radius: 32px;
}

.custom-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #32bb78;
    gap: 10px;
}
